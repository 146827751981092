<template>
  <div>
    <ejs-dialog
        ref="lockerStatusPopup"
        :header="`락카 상황판 (` + bsnDate + `)`"
        :allowDragging="true"
        :showCloseIcon="true"
        :width="w"
        :height="h"
        :animationSettings="{ effect: 'None' }"
        :isModal="true"
        :enableResize="true"
        :close="onLockerStatusPopupClosed"
    >
      <div class="window lockerStatus">
        <div class="content-wrapper">
          <div class="move" v-if="!isFullscreen || zoom > 1">
            <div class="item" @click="onMoveClick(0)">
              ◀
            </div>
            <div class="item" @click="onMoveClick(1)">
              ▶
            </div>
          </div>
          <div class="slider">
            <input
                type="range"
                step="0.1"
                min="0.7"
                max="10"
                class="slider"
                v-model="zoom"
            />
            X {{ zoom }}
          </div>
          <div class="target" @click="onTargetClick"/>
          <div class="content-lookup">
            <div class="lookup-left">
              <ul class="lookup-condition">
                <li class="field">
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="checkbox"
                                v-model="isAuto"
                                :checked="isAuto"
                                :disabled="timeInterval === 0"
                            />
                            <i></i>
                            <div class="label">자동</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <ul class="content">
                    <li class="item">
                      <ejs-numerictextbox
                          width="70"
                          v-model="timeInterval"
                          format="n"
                          :min="0"
                          :step="1"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="lookup-lookup">
                <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    @click.native="fetchLockerData"
                    :ignore="isPopupOpened"
                >
                  조회
                </erp-button>
              </div>
              <div class="section-header">
                <div class="header-left">
                  <ul class="header-label">
                    <li class="field">
                      <ul class="content">
                        <li
                            class="item"
                            v-for="code in lockerStatusCode"
                            :key="code.comCode"
                        >
                          <i
                              :style="{ 'background-color': code.colorValue }"
                              v-if="code.comCode !== 'NOT'"
                          />
                          <div class="label" v-if="code.comCode !== 'NOT'">
                            {{ code.comCode === "RESVE" ? "보관" : code.comName }}
                          </div>
                        </li>
                        <li class="item" style="margin-left: -4px">
                          <i :style="{ 'background-color': '#9764b6' }"/>
                          <div class="label">2부</div>
                        </li>
                        <li class="item" style="font-weight: bold">
                          <div class="label">남[+] 여[-] 가변[*]</div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div class="header-switch">
                    <div class="title">
                      {{ isFullscreen ? '가변락카 자동발행' : '가변락카' }}
                    </div>
                    <div class="switch">
                      <ejs-switch
                          v-model="systemConfigInfo.variableLockerUseFlag"
                          :checked="systemConfigInfo.variableLockerUseFlag"
                          @change="onVariableLockerUseFlagChange"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="lookup-condition right">
                <li class="field" v-if="isFullscreen">
                  <div class="title">
                    가변락카 구역별 자동발행
                  </div>
                  <ul class="content">
                    <div class="item">
                      <ejs-multiselect
                          v-model="autoArgmtFlagBySectors"
                          :dataSource="autoArgmtFlagBySectorsDataSource"
                          cssClass="lookup-condition-multiselect"
                          width="240px"
                      />
                    </div>
                  </ul>
                </li >
                <li class="field" v-if="isFullscreen">
                  <ul class="content">
                    <li class="item button">
                      <ul class="button">
                        <li class="save">
                          <erp-button
                            button-div="SAVE"
                            @click.native="autoArgmtFlagSettingByVariableLocker"
                          >
                            저장
                          </erp-button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field course">
                  <div class="title">뷰 모드 (F9)</div>
                  <ul class="content">
                    <li class="item" style="width: 85px">
                      <ejs-dropdownlist
                          cssClass="lookup-condition-dropdown"
                          ref="visitSearchCourseCode"
                          v-bind="courseCodeDropdownListProps"
                          v-model="viewDiv"
                          @change="fetchLockerData"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field" v-if="isFullscreen">
                  <ul class="content">
                    <li class="item button">
                      <ul class="button">
                        <li>
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onVariableLockerButtonClicked"
                          >
                            가변
                          </erp-button>
                        </li>
                        <li class="reset">
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="initLockerStatus"
                          >
                            초기화
                          </erp-button>
                        </li>
                        <li class="close">
                          <erp-button
                              button-div="CLOSE"
                              :is-icon-custom="true"
                              @click.native="closeLockerkeyAssignPopup"
                          >
                            닫기
                          </erp-button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </div>
            </div>
          </div>
          <div
            class="section-caption"
            v-if="currentBizCode === 'JY1'"
          >
            <div class="caption-navigation">
              <ejs-tab
                ref="lockerDivTab"
                :items="lockerDivTabList"
                :showCloseButton="false"
                heightAdjustMode="Auto"
                overflowMode="Popup"
                @selected="tabSelected"
              />
            </div>
          </div>
          <locker-status-j01
            v-if="currentBizCode === 'J01'"
            :propsIsFullscreen="isFullscreen"
            :propsViewDiv="viewDiv"
            :propsLoading="loading"
            :propsZoom="zoom"
            :propsLockerStatusList="lockerStatusList"
            :propsSearchValue="searchValue"
            @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
            @onMouseDown="onMouseDown"
          />
          <locker-status-k01
            v-if="currentBizCode === 'K01'"
            :propsIsFullscreen="isFullscreen"
            :propsViewDiv="viewDiv"
            :propsLoading="loading"
            :propsZoom="zoom"
            :propsLockerStatusList="lockerStatusList"
            :propsSearchValue="searchValue"
            @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
            @onMouseDown="onMouseDown"
          />
          <locker-status-l01
            ref="lockerStatusL01"
            v-if="currentBizCode === 'L01'"
            :propsIsFullscreen="isFullscreen"
            :propsViewDiv="viewDiv"
            :propsLoading="loading"
            :propsZoom="zoom"
            :propsLockerStatusList="lockerStatusList"
            :propsSearchValue="searchValue"
            :bsnDate="bsnDate"
            @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
            @onMouseDown="onMouseDown"
          />
          <locker-status-e01
            ref="lockerStatusE01"
            v-if="currentBizCode === 'E01'"
            :propsIsFullscreen="isFullscreen"
            :propsViewDiv="viewDiv"
            :propsLoading="loading"
            :propsZoom="zoom"
            :propsLockerStatusList="lockerStatusList"
            :propsSearchValue="searchValue"
            :bsnDate="bsnDate"
            @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
            @onMouseDown="onMouseDown"
          />
          <locker-status-h01
            ref="lockerStatusH01"
            v-if="currentBizCode === 'H01'"
            :propsIsFullscreen="isFullscreen"
            :propsViewDiv="viewDiv"
            :propsLoading="loading"
            :propsZoom="zoom"
            :propsLockerStatusList="lockerStatusList"
            :propsSearchValue="searchValue"
            :bsnDate="bsnDate"
            :propsFloor="floor"
            @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
            @onMouseDown="onMouseDown"
          />
          <locker-status-m01
            ref="lockerStatusM01"
            v-if="currentBizCode === 'M01'"
            :propsIsFullscreen="isFullscreen"
            :propsViewDiv="viewDiv"
            :propsLoading="loading"
            :propsZoom="zoom"
            :propsLockerStatusList="lockerStatusList"
            :propsSearchValue="searchValue"
            :bsnDate="bsnDate"
            @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
            @onMouseDown="onMouseDown"
          />
          <locker-status-jY1
              ref="lockerStatusJY1"
              v-if="currentBizCode === 'JY1'"
              :propsIsFullscreen="isFullscreen"
              :propsViewDiv="viewDiv"
              :propsLoading="loading"
              :propsZoom="zoom"
              :propsLockerStatusList="lockerStatusList"
              :propsSearchValue="searchValue"
              :bsnDate="bsnDate"
              :propsLockerDiv = "lockerDiv"
              :propsVariableFlag = "variableFlag"
              @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
              @onMouseDown="onMouseDown"
          />
          <locker-status-l-d1
              ref="lockerStatusLD1"
              v-if="currentBizCode === 'LD1'"
              :propsIsFullscreen="isFullscreen"
              :propsViewDiv="viewDiv"
              :propsLoading="loading"
              :propsZoom="zoom"
              :propsLockerStatusList="lockerStatusList"
              :propsVariableLockerGenderLD1="variableLockerGenderLD1"
              :propsVariableLockerGenderLD2="variableLockerGenderLD2"
              :propsSearchValue="searchValue"
              :propsTotalLockerArray="totalLockerArray"
              :bsnDate="bsnDate"
              @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
              @onMouseDown="onMouseDown"
          />
          <locker-status-e-d1
              ref="lockerStatusED1"
              v-if="currentBizCode === 'ED1'"
              :propsIsFullscreen="isFullscreen"
              :propsViewDiv="viewDiv"
              :propsLoading="loading"
              :propsZoom="zoom"
              :propsLockerStatusList="lockerStatusList"
              :propsSearchValue="searchValue"
              :bsnDate="bsnDate"
              @openLockerKeyAssignPopup="openLockerKeyAssignPopup"
              @onMouseDown="onMouseDown"
          />
        </div>
        <div class="windowFooter">
          <ul class="lookup-condition">
            <li class="field">
              <div class="title">락카 찾기 (INSERT)</div>
              <ul class="content">
                <li class="item">
                  <input-text
                    ref="searchValue"
                    v-model.trim="searchValue"
                    placeholder="락카/내장객/TEE-OFF"
                    @click.native="searchValue = ''"
                    @keydown.enter="findLocker"
                  />
                </li>
              </ul>
              <div class="lookup-lookup">
                <erp-button
                    button-div="GET"
                    @click.native="findLocker"
                >
                  찾기
                </erp-button>
              </div>
            </li>
            <li class="field">
              <div class="title">
                남은 락카: {{ getCountByEmptyLocker }}
              </div>
            </li>
          </ul>
          <ul class="button">
            <li>
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="showLockerKeepVisitorStatus"
                  :ignore="isPopupOpened"
              >
                보관락카현황
              </erp-button>
            </li>
            <li>
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onVariableLockerButtonClicked"
              >
                가변
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="initLockerStatus"
              >
                초기화
              </erp-button>
            </li>
            <li v-if="isFullscreen">
              <erp-button
                  button-div="FILE"
                  @click.native="download"
              >
                이미지 출력
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeLockerkeyAssignPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <locker-key-assign-popup
        v-if="showLockerKeyAssignPopup"
        ref="lockerKeyAssignPopup"
        @popupClosed="onLockerkeyAssignPopupClose"
        :fromFrontCheckinVisitPopupOpened="this.popupOpened"
    />
    <variable-locker-div-change-popup
        v-if="isVariableLockerDivChangePopupOpen"
        ref="variableLockerDivChangePopup"
        :sectors="autoArgmtFlagBySectorsDataSource"
        @popupClosed="onVariableLockerDivChangePopupClose"
    />
    <ejs-dialog
      ref="lockerKeepVisitorPopup"
      header="전일 락카보관 현황"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="false"
      :position="{ X: 1400, Y: 590 }"
      width="300"
      height="267"
      v-show="isLockerKeepVisitorStatus"
      @close="onLockerKeepVisitorPopupClosed"
    >
      <div class="window">
        <div class="windowContent" style="height: 100%;">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body" style="border: none;">
                    <ejs-grid-wrapper
                      :provides="grid"
                      :allowResizing="false"
                      :allowSorting="false"
                      :allowFiltering="false"
                      :allowEditing="false"
                      :dataSource="lockerKeepVisitorList"
                      :columns="lockerKeepVisitorGridColumns"
                      style="height: 100%;"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .section-header {margin: 0}
body .section-header .header-label {margin: 0 -5px;padding: 3px 0 0}
body .lookup-condition.right {display: flex;flex: 1;justify-content: flex-end}
body .section-header .header-switch {position: unset !important; margin-top: 4px;}
.content-wrapper > .move {display: flex;position: absolute;left: calc(50% - 36.5px);bottom: 0;z-index: 1}
.content-wrapper > .move > .item {display: flex;align-items: center;justify-content: center;width: 36px;height: 36px;color: #fff;font-size: 13px;background-color: #2e7458;cursor: pointer}
.content-wrapper > .move > .item:hover {background-color: #000}
.content-wrapper > .move > .item:first-child {border-right: 1px dashed #174e35;}
.content-wrapper > .slider {display: flex;position: absolute;right: 5px;bottom: 43px;font-size: 13px;z-index: 1}
.content-wrapper > .target {position: absolute;right: 10.5rem;bottom: 42px;width: 28px;height: 22px; background-image: url(../../../assets/images/common/target.png);background-size: cover;cursor: pointer;z-index: 1}
.content-wrapper > .target:hover {opacity: .8}
body .lookup-condition .field {margin: 0;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import lockerKeyAssignPopup from './LockerKeyAssignPopup';
import variableLockerDivChangePopup from './VariableLockerDivChangePopup';
import LockerStatusJ01 from "@/views/checkin/popup/lockerStatus/LockerStatusJ01";
import LockerStatusK01 from "@/views/checkin/popup/lockerStatus/LockerStatusK01";
import LockerStatusL01 from "@/views/checkin/popup/lockerStatus/LockerStatusL01";
import LockerStatusE01 from "@/views/checkin/popup/lockerStatus/LockerStatusE01";
import LockerStatusH01 from "@/views/checkin/popup/lockerStatus/LockerStatusH01";
import LockerStatusM01 from "@/views/checkin/popup/lockerStatus/LockerStatusM01.vue";
import LockerStatusJY1 from "@/views/checkin/popup/lockerStatus/LockerStatusJY1";
import LockerStatusLD1 from "@/views/checkin/popup/lockerStatus/LockerStatusLD1";
import LockerStatusED1 from "@/views/checkin/popup/lockerStatus/LockerStatusED1";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Resize,
  ForeignKey,
  Aggregate,
  Filter,
  Group,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";

import {
  getLockerStatusList,
  putInitLockerStatus,
} from '@/api/frontManagement';
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesSetStandardInfos,
  commonCodesGetComName,
} from '@/utils/commonCodes';
import { maker } from '@/utils/maker';
import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import InputText from "@/components/common/text/InputText";
import {getSystemConfigInfo, patchSystemConfigInfo} from '@/api/systemConfig';
import {toPng} from 'html-to-image';
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import {getVisitCopy} from "@/api/frontManagementCheckin";
import {getDateOfPrevious} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: 'lockerStatusPopup',
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    popupOpened: Boolean,
  },
  async created() {
    this.lockerStatusCode.splice(4, 0, {
      comCode: 'VIP',
      comName: 'VIP',
      colorValue: '#AAF0D1',
    });

    window.addEventListener('keydown', this.keyDownEvent);
    window.addEventListener('keyup', this.keyUpEvent);

    await this.fetchSystemConfigInfo();
  },
  components: {
    InputText,
    lockerKeyAssignPopup,
    variableLockerDivChangePopup,
    LockerStatusJ01,
    LockerStatusK01,
    LockerStatusL01,
    LockerStatusE01,
    LockerStatusH01,
    LockerStatusM01,
    LockerStatusJY1,
    LockerStatusLD1,
    LockerStatusED1,
    EjsGridWrapper,
    ErpButton
  },
  data() {
    return {
      isLockerKeepVisitorStatus: true,
      grid: [Aggregate, Filter, Resize, Group, ForeignKey, VirtualScroll],
      lockerKeepVisitorGridColumns: [
        {
          field: "lockerNo",
          headerText: "락카",
          width: "60",
          textAlign: "center",
        },
        {
          field: "visitName",
          headerText: "내장객명",
          width: "70",
        },
        {
          field: "sexCode",
          headerText: "성별",
          width: "60",
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "SEX_CODE",
        }
      ],
      lockerKeepVisitorList: [],
      currentBizCode: getCurrentBizCode(),
      systemConfigInfo: {
        variableLockerUseFlag: false,
      },
      isVariableLockerDivChangePopupOpen: false,
      lockerStatusCode: commonCodesGetCommonCode('LOCKER_STATUS'),
      showLockerKeyAssignPopup: false,
      bsnDate: null,
      lockerDiv: 'M',
      viewDiv: 'TIME',
      searchValue: '',
      timeInterval: 0,
      zoom: 1,
      left: 0,
      top: 0,
      x: 0,
      y: 0,
      oneRowCount: 5,
      loading: false,
      lockerStatusList: [],
      renderMData: null,
      renderFData: null,
      renderAData: null,
      variableLockerArray: [],
      womenLockerArray: [],
      menLockerArray: [],
      totalLockerArray: [],
      variableLockerGenderLD1: null,
      variableLockerGenderLD2: null,
      cancelReason: null,
      isAuto: false,
      intervalHandler: null,
      isVisitLockerNo: false,
      partDiv2StartTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          1,
          false,
      ),
      partDiv2EndTime: commonCodesGetCommonCodeAttrbByCodeAndIdx(
          'PART_DIV',
          '2',
          2,
          false,
      ),
      isFullscreen: true,
      floor: 1,
      variableFlag: false,
      lockerDivTabList: [
        {
          header: { text: "남성 락커 룸" },
          lockerDiv: "M",
          variableFlag: false,
        },
        {
          header: { text: "여성 락커 룸" },
          lockerDiv: "F",
          variableFlag: false,

        },
        {
          header: { text: "가변(남) 락커 룸" },
          lockerDiv: "M",
          variableFlag: true,
        },
      ],
      autoArgmtFlagBySectors: [],
      orgAutoArgmtFlagBySectors: [],
    };
  },
  async mounted() {
    await this.$nextTick();
    this.$refs.searchValue.focusIn();
    const $el = document.getElementById('_dialog-header');
    const $copyEl = $el.firstElementChild.cloneNode(true);
    $copyEl.id = 'custom-fullscreen';
    $copyEl.classList.add('fullscreen');
    $copyEl.setAttribute('title', 'Fullscreen');
    $copyEl.setAttribute('aria-title', 'Fullscreen');
    $copyEl.addEventListener('click', () => this.onToggleScreenSizeClick());
    $copyEl.removeEventListener('click', () => this.onToggleScreenSizeClick());
    $el.insertBefore($copyEl, $el.lastChild);
    window.addEventListener('mousewheel', this.onMouseWheel, {passive: false});
    window.addEventListener('DOMMouseScroll', this.onMouseWheel, false);

    this.lockerKeepVisitorList = [];
    const response = await getVisitCopy(getDateOfPrevious(this.bsnDate));
    response?.value?.map(value => {
      value?.visitInfoList?.map(visitor => {
        this.lockerKeepVisitorList.push(visitor);
      });
    });

    if (!(this.lockerKeepVisitorList.length > 0)) {
      this.$refs.lockerKeepVisitorPopup.hide();
      this.isLockerKeepVisitorStatus = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalHandler);
    window.removeEventListener('keydown', this.keyDownEvent);
    window.removeEventListener('keyup', this.keyUpEvent);
  },
  watch: {
    isFullscreen: function () {
        this.setScreenZoom();
    },
    isAuto() {
      if (this.isAuto && this.timeInterval !== 0) {
        this.intervalHandler = setInterval(() => {
          this.fetchLockerData();
        }, this.timeInterval * 1000);
      } else {
        clearInterval(this.intervalHandler);
      }
    },
    timeInterval() {
      if (this.timeInterval === 0) {
        this.isAuto = false;
      }
    },
    searchValue(searchValue) {
      this.searchValue = searchValue.replace(/[+\-*]+/gim, '');
    },
  },
  computed: {
    autoArgmtFlagBySectorsDataSource() {
      const lockers = this.lockerStatusList.map(item => item.lockerBoardList)?.flat();

      const sectors =
        lockers.length > 0
          ? orderBy(Object.keys(
              groupBy(
                lockers
                  .filter(item => item.variableFlag)
                  .map(item => {
                    return {
                      text: item.sector,
                      value: item.sector,
                    };
                  }),
                "value"
              )
            ))
          : [];
      return sectors;
    },
    isPopupOpened() {
      return (
          this.isVariableLockerDivChangePopupOpen || this.showLockerKeyAssignPopup
      );
    },
    w() {
      return this.isFullscreen ? 'calc(100%)' : 1200;
    },
    h() {
      return this.isFullscreen ? '100%' : 760;
    },
    getCountByEmptyLocker() {
      let count = 0, empty = 0;
      this.lockerStatusList
          ?.forEach(data => data?.lockerBoardList
              ?.forEach(board => board.lockerStatus === 'EMPTY'
                  ? empty++
                  : count++
              )
          );
      return `${empty}/${count + empty}`;
    },
    courseCodeDropdownListProps() {
      const dataSource = [
        {
          comCode: 'LOCKER',
          comName: '락카번호',
        }, {
          comCode: 'VISIT',
          comName: '내장객명',
        }, {
          comCode: 'TIME',
          comName: 'TEE-OFF',
        }, {
          comCode: 'VISIT_TIME',
          comName: '체크인 시간',
        }];
      return {
        allowFiltering: false,
        dataSource,
        fields: {text: 'comName', value: 'comCode'},
      };
    },
  },
  methods: {
    async keyDownEvent(event) {
      if (this.isPopupOpened) {
        return;
      }
      const {key} = event;
      if (key === 'ArrowLeft' || key === 'ArrowRight') {
        this.onMoveClick(key === 'ArrowLeft' ? 0 : 1);
      } else if (key === 'Insert') {
        await this.$nextTick();
        this.searchValue = '';
        this.$refs.searchValue.focusIn();
      } else if (key === 'F9') {
        switch (this.viewDiv) {
          case 'LOCKER':
            this.viewDiv = 'VISIT';
            break;
          case 'VISIT':
            this.viewDiv = 'TIME';
            break;
          case 'TIME':
            this.viewDiv = 'LOCKER';
            break;
        }
        await this.fetchLockerData();
        await this.$nextTick();
        this.$refs.searchValue.focusIn();
      }
    },
    keyUpEvent(event) {
      if (this.isPopupOpened) {
        return;
      }
      const {key} = event;
      if (key === '+') {
        this.openLockerKeyAssignPopup(null, 'M');
      } else if (key === '-') {
        this.openLockerKeyAssignPopup(null, 'F');
      } else if (key === '*') {
        this.openLockerKeyAssignPopup(null, 'A');
      }
    },
    async showLockerStatusPopup(bsnDate, isVisitLockerNo = false) {
      this.bsnDate = bsnDate;
      this.isVisitLockerNo = isVisitLockerNo;
      await this.fetchLockerData();
    },
    onLockerStatusPopupClosed() {
      this.$emit('popupClosed');
    },
    openLockerKeyAssignPopup(lockerNo, lockerDiv = null) {
      this.showLockerKeyAssignPopup = true;
      this.$nextTick(() => {
        this.$refs.lockerKeyAssignPopup.showLockerKeyAssignPopup(
            this.bsnDate,
            lockerNo,
            lockerDiv,
            this.isVisitLockerNo,
        );
      });
    },
    async onToggleScreenSizeClick() {
      this.isFullscreen = !this.isFullscreen;
      await this.$nextTick();
      const $el = document.getElementById('custom-fullscreen');
      $el.classList.remove(!this.isFullscreen ? 'fullscreen' : 'notfullscreen');
      $el.classList.add(this.isFullscreen ? 'fullscreen' : 'notfullscreen');
      const powderWrapper = document.getElementById("powder-wrapper");
      powderWrapper.classList.remove(!this.isFullscreen ? 'fullscreen' : 'notfullscreen');
      powderWrapper.classList.add(this.isFullscreen ? 'fullscreen' : 'notfullscreen');
    },
    findLocker() {
      if (this.searchValue === '') {
        return;
      }
      let flag = false;
      this.lockerStatusList?.forEach(data => {
        const locker = data.lockerBoardList?.find(item => this.searchValue === item.lockerPrtNo);
        if (locker) {
          flag = true;
          this.openLockerKeyAssignPopup(locker.lockerNo);
        }
      });
      if (!flag) {
        const comName = this.courseCodeDropdownListProps.dataSource.find(item => item.comCode === this.viewDiv)?.comName;
        this.errorToast(`해당 ${comName}${maker(comName) ? '는' : '은'} 없습니다`);
      }
    },
    async onLockerkeyAssignPopupClose({
      isRefresh,
      keyAssignStatus,
      setLockerNo,
      lockerPrtNo,
      lockerDiv,
      sector,
      sectorPrtMale,
      sectorPrtFemale,
    }) {
      this.showLockerKeyAssignPopup = false;
      if (isRefresh) {
        await this.fetchLockerData();
      }
      if (this.isVisitLockerNo && keyAssignStatus === 'ASSIGN') {
        this.$emit('popupClosed', setLockerNo, lockerDiv, sector, sectorPrtMale, sectorPrtFemale, lockerPrtNo);
      }
      await this.$nextTick();
      this.$refs.searchValue.focusIn();
    },
    closeLockerkeyAssignPopup() {
      this.$refs.lockerStatusPopup.hide();
    },
    async initLockerStatus() {
      await putInitLockerStatus(this.bsnDate);
      await this.fetchLockerData();
    },
    async fetchLockerData() {
      this.loading = true;
      const {value} = await getLockerStatusList(this.bsnDate, null, this.viewDiv);
      this.loading = false;
      this.lockerStatusList = value;
      const lockers = value.map(item => item.lockerBoardList).flat();
      this.autoArgmtFlagBySectors =
        lockers.length > 0
          ? Object.keys(
              groupBy(
                lockers
                  .filter(item => item.variableFlag && item.autoArgmtFlag)
                  .map(item => {
                    return {
                      text: item.sector,
                      value: item.sector,
                    };
                  }),
                "value"
              )
            )
          : [];
      this.orgAutoArgmtFlagBySectors = JSON.parse(JSON.stringify(this.autoArgmtFlagBySectors));
      this.setLockerDataToRenderData(value);
      this.setScreenZoom();
      await this.$nextTick();
      this.$refs.searchValue.focusIn();
    },
    setScreenZoom() {
      this.isFullscreen ? this.zoom = 0.8: this.zoom = 1;
    },

    setLockerDataToRenderData(lockerData) {
      if (lockerData === null) {
        return;
      }

      const flattenRecords = flatten(
          lockerData.map(({lockerBoardList}) => lockerBoardList),
      );

      // Sector F
      const lockerDivFData = flattenRecords.filter(
          ({variableFlag, lockerDiv}) => !variableFlag && lockerDiv === 'F',
      );
      const lockerDivFDataGroups = groupBy(lockerDivFData, 'sector');
      this.renderFData = Object.keys(lockerDivFDataGroups).map((sector) => ({
        sector,
        lockerDiv: 'F',
        lockerBoardList: lockerDivFDataGroups[sector],
      }));

      // Sector M
      const lockerDivMData = flattenRecords.filter(
          ({variableFlag, lockerDiv}) => !variableFlag && lockerDiv === 'M',
      );
      const lockerDivMDataGroups = groupBy(lockerDivMData, 'sector');
      this.renderMData = Object.keys(lockerDivMDataGroups).map((sector) => ({
        sector,
        lockerDiv: 'M',
        lockerBoardList: lockerDivMDataGroups[sector],
      }));

      // Sector A (variable)
      const lockerDivAData = flattenRecords.filter(
          ({variableFlag}) => !!variableFlag,
      );
      const lockerDivADataGroups = groupBy(lockerDivAData, 'sector');
      this.renderAData = Object.keys(lockerDivADataGroups).map((sector) => ({
        sector,
        lockerDiv: 'A',
        lockerBoardList: lockerDivADataGroups[sector],
      }));
      this.menLockerArray = this.renderMData.map((d) => d.lockerBoardList).flat();
      this.womenLockerArray = this.renderFData.map((d) => d.lockerBoardList).flat();
      this.variableLockerArray = this.renderAData.map((d) => d.lockerBoardList).flat();
      this.totalLockerArray = [].concat(this.menLockerArray,this.womenLockerArray,this.variableLockerArray);
      this.variableLockerGenderLD1 = commonCodesGetComName('SEX_CODE',this.variableLockerArray.find((f) => f.lockerNo === "283")?.lockerDiv);
      this.variableLockerGenderLD2 = commonCodesGetComName('SEX_CODE',this.variableLockerArray.find((f) => f.lockerNo === "330")?.lockerDiv);
    },
    async onVariableLockerDivChangePopupClose(isSaved) {
      this.isVariableLockerDivChangePopupOpen = false;
      await this.$nextTick();
      this.$refs.searchValue.focusIn();
      if (isSaved) {
        await this.fetchLockerData();
      }
    },
    onVariableLockerButtonClicked() {
      this.isVariableLockerDivChangePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.variableLockerDivChangePopup.showVariableLockerDivChangePopup(
            this.bsnDate,
        );
      });
    },
    onMouseDown(event) {
      const el = document.getElementById('powder-wrapper');
      el.style.cursor = 'grabbing';
      el.style.userSelect = 'none';
      this.left = el.scrollLeft;
      this.top = el.scrollTop;
      this.x = event.clientX;
      this.y = event.clientY;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseMove(event) {
      const el = document.getElementById('powder-wrapper');
      const x = event.clientX - this.x;
      const y = event.clientY - this.y;
      el.scrollLeft = this.left - x;
      el.scrollTop = this.top - y;
    },
    onMouseUp() {
      const el = document.getElementById('powder-wrapper');
      el.style.cursor = 'grab';
      el.style.removeProperty('user-select');
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    },
    onMoveClick(move) {
      const el = document.getElementById('powder-wrapper');
      el.scrollLeft = move === 0
          ? 0
          : 100000;
    },
    onTargetClick() {
      this.zoom = 1;
      this.left = 0;
      this.top = 0;
      this.x = 0;
      this.y = 0;
    },
    onMouseWheel(event) {
      if (!event.ctrlKey) {
        return;
      }
      event.preventDefault();
      const zoom = event.deltaY > 0 ? this.zoom - 0.1 : this.zoom + 0.1;
      if (zoom < 0.1 || zoom > 10) {
        return;
      }
      this.zoom = Number(zoom.toFixed(1));
    },
    getResizingPrintLabel(print) {
      return isNaN(print) && this.viewDiv === 'VISIT'
          ? print.substr(0, 3)
          : print;
    },
    async fetchSystemConfigInfo() {
      const {
        value: {confCom},
      } = await getSystemConfigInfo();
      this.systemConfigInfo = confCom;
    },
    async onVariableLockerUseFlagChange() {
      const saveData = {
        variableLockerUseFlag: this.systemConfigInfo.variableLockerUseFlag,
      };
      const {data} = await patchSystemConfigInfo(saveData);
      const {standardInfos} = data;

      // 시스템 환경설정 저장
      commonCodesSetStandardInfos(standardInfos);
    },
    download() {
      const node = document.getElementById('powder-wrapper');
      toPng(node, {backgroundColor: '#FFF'})
        .then(dataUrl => {
          const link = document.createElement('a');
          link.download = `locker_${this.bsnDate}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch(err => {
          alert(err);
        });
    },
    showLockerKeepVisitorStatus() {
      if (this.$refs.lockerStatusL01) {
        this.$refs.lockerStatusL01.showLockerKeepVisitorStatus();
      } else if (this.$refs.lockerStatusJY1) {
        this.$refs.lockerKeepVisitorPopup.show();
        this.isLockerKeepVisitorStatus = true;
      } else if (this.$refs.lockerStatusED1) {
        this.$refs.lockerStatusED1.showLockerKeepVisitorStatus();
      }
    },
    tabSelected(args) {
      this.lockerDiv = this.lockerDivTabList[args.selectedIndex].lockerDiv;
      this.variableFlag = this.lockerDivTabList[args.selectedIndex].variableFlag;

      this.setScreenZoom();
    },
    onLockerKeepVisitorPopupClosed() {
      this.$refs.lockerKeepVisitorPopup.hide();
      this.isLockerKeepVisitorStatus = false;
    },
    async autoArgmtFlagSettingByVariableLocker() {
      const addSector = this.autoArgmtFlagBySectors.filter(sector => !this.orgAutoArgmtFlagBySectors.includes(sector));
      const deleteSector = this.orgAutoArgmtFlagBySectors.filter(sector => !this.autoArgmtFlagBySectors.includes(sector));

      if (addSector.length === 0 && deleteSector.length === 0) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      const tsLockers =
        addSector.map(sector => {
          return {
            sector: sector,
            autoArgmtFlag: true,
          };
        }).concat(
          deleteSector.map(sector => {
            return {
              sector: sector,
              autoArgmtFlag: false,
            };
          })
        );

      try {
        await GolfErpAPI.autoArgmtFlagSettingByVariableLocker(tsLockers);

        await this.fetchLockerData();

        this.infoToast("저장되었습니다");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
